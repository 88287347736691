@font-face {
  font-family: Metrophobic;
  src: url(/public/Metrophobic/Metrophobic-Regular.ttf);
}

body {
  font-family: Metrophobic;
  color: white;
}

.angular {
  clip-path: polygon(
    10% 0%,
    100% 0,
    100% 0,
    100% 90%,
    90% 100%,
    0 100%,
    0 100%,
    0 10%
  );
}

.characterbox .charactericon::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.9)
  );
}

.characterbox .charactericon {
  filter: brightness(100%);
  -webkit-filter: brightness(100%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 0;
  position: relative;
  border-radius: 4px;
}

.characterbox .charactericon:hover {
  filter: brightness(100%);
  -webkit-filter: brightness(80%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.particlesbox {
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  z-index: -10;
}

.lightbox {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  height: 100%;
}

.lightbox-img {
  max-height: 85%;
  width: auto;
}

.readfont {
  line-height: 1.6em;
  font-size: larger;
  letter-spacing: 1px;
}

.die {
  display: grid;
  place-items: center;
  grid-template-areas: "inner-div";
}

.die > * {
  grid-area: inner-div;
}

.statinput {
  max-width: 100%;
  border: none;
}

.statinput::-webkit-outer-spin-button,
.statinput::-webkit-inner-spin-button {
  display: none;
}

.statinput[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.statinput:focus {
  border: none;
  outline: none;
}

.statBloc {
  clip-path: polygon(10% 0, 0 15%, 0 85%, 10% 100%, 100% 100%, 100% 0);
}

.dicemenu {
  clip-path: polygon(0 0, 95% 0, 100% 50%, 100% 100%, 0 100%);
}

.statbar {
  clip-path: polygon(0 0, 97% 0, 100% 30%, 100% 100%, 0 100%);
}

@media only screen and (max-width: 640px) {
  .statbar {
    clip-path: none;
  }
}
